const modalStyles = {
  content: {
    inset: "auto 0px 0px 0px",
    borderRadius: "16px 16px 0 0",
  },
  overlay: {
    backgroundColor: "#00000033",
  },
};

export default modalStyles;
