import ReactModal from "react-modal";
import React from "react";
import PropTypes from "prop-types";
import Button from "../forms/Button";
import modalStyles from "./sociable-hour-banner.resource";
import { useAppContext } from "../../utils/Contexts/AppContext";

export default function SociableHourBanner({ showBanner, setShowBanner }) {
  const { appTheme } = useAppContext();
  return (
    <ReactModal
      isOpen={showBanner}
      onRequestClose={() => setShowBanner(false)}
      style={modalStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className={appTheme}>
        <div className="space-y-2 text-xs mb-4 text-[#566370]">
          <h2 className="text-center text-[0.75rem] font-bold text-[#1A1A1A]">
            Out of Sociable hours
          </h2>
          <p className="text-center">
            Promotional messages are sent between 8 am and 6 pm daily. If you
            choose to send it now, your contacts will receive it during these
            hours.
          </p>
        </div>

        <div className="space-y-2">
          <Button onClick={() => setShowBanner(false)}>Continue</Button>
        </div>
      </div>
    </ReactModal>
  );
}

SociableHourBanner.propTypes = {
  showBanner: PropTypes.bool.isRequired,
  setShowBanner: PropTypes.func.isRequired,
};
